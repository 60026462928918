import {
  getPackageListApi,
  getPayCodeApi,
  getPayCodeUrlApi,
  confirmPayInfoApi,
} from "./api/pay"
import { isDesktop } from "./utils"

const pay = {
  intervalTimer: null,

  async getPackageList() {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await getPackageListApi()
        const data = res.data
        if (data.code >= 300) {
          reject({
            code: data.code,
            msg: data.msg,
          })
        }
        resolve(data.data)
      } catch (e) {
        reject({
          msg: "请求失败",
        })
      }
    })
  },
  async getPayCode(plan_type, goods_id) {
    const type = isDesktop()
    if (type != 4) {
      if (type == 0) {
        return await this.getPayCodeUrl(plan_type, goods_id)
      } else {
        return await this.getPayQrCode(plan_type, goods_id)
      }
    }
  },
  async getPayQrCode(plan_type, goods_id) {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await getPayCodeApi(plan_type, goods_id)
        const data = res.data
        if (data.code >= 300) {
          reject({
            code: data.code,
            msg: data.msg,
          })
        }
        resolve(data.data)
      } catch (e) {
        reject({
          msg: "请求失败",
        })
      }
    })
  },
  async getPayCodeUrl(plan_type, goods_id) {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await getPayCodeUrlApi(plan_type, goods_id)
        const data = res.data
        if (data.code >= 300) {
          reject({
            code: data.code,
            msg: data.msg,
          })
        }
        resolve({ ...data.data, mobile: true })
      } catch (e) {
        reject({
          msg: "请求失败",
        })
      }
    })
  },
  async payPoll(order_id) {
    let overTime = 60
    const that = this

    //获取支付结果
    const confirmPayInfo = async () => {
      return new Promise(async (resolve) => {
        try {
          const res = await confirmPayInfoApi(order_id)
          const data = res.data
          if (data.code >= 300) {
            resolve({
              status: 0,
              msg: data.msg,
            })
          }
          const payData = data.data.status
          resolve({
            status: payData.code,
            msg: payData.msg,
          })
        } catch (e) {
          resolve({
            status: 0,
            msg: "请求失败",
          })
        }
      })
    }

    return new Promise((resolve, reject) => {
      that.intervalTimer = setInterval(async () => {
        if (overTime <= 0) {
          reject({
            status: 0,
            msg: "支付失败",
          })
          clearInterval(that.intervalTimer)
        } else {
          overTime -= 2
          const res = await confirmPayInfo()
          if (res.status === 1) {
            clearInterval(that.intervalTimer)
            resolve({
              status: 1,
              msg: "支付成功",
            })
          }
        }
      }, 2000)
    })
  },

  // 停止轮询二维码登录
  stopInterval() {
    clearInterval(this.intervalTimer)
  },
}

export default pay
