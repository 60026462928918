<template>
  <div class="author-main">
        <div class="author-main-left">
			<div class="author-left-top">
				<img src="../../assets/author/author-bangdan-img.png" class="author-bangdan-img"/>
				<div class="author-top-ret gestures">
					<img src="../../assets/author/author-shuoming.png" class="author-shuoming-img"/>
					<div class="author-shuoming-text">榜单说明</div>
				</div>
			</div>
			<div class="date-choice-item gestures" @click="changeChoice">
				<img src="../../assets/author/date-chone-icon.png" class="date-choice-img"/>
				<div class="date-choice-stage">第<span class="stage-val">12</span>期</div>
				<div class="date-choice-vals">2022-12-01~2022-12-31</div>
				<img src="../../assets/author/date-chone-down.png" class="date-choice-down"/>
				
				<div class="date-choice-list" v-show="choiceShow">
					<div class="date-choice-option" v-for="(value,index) in 10" :key="index">
						<div class="date-choice-label">第<span>{{index+1}}</span>期</div>
						<div class="date-choice-text">2022-12-01~2022-12-31</div>
					</div>
				</div>
			</div>
			<!-- v-if="rankingList.length > 0" -->
			<div class="ranking-topthree-item">
				<div class="ranking-two-item">
					<img src="../../assets/author/pai-two.png" class="ranking-two-img"/>
					<div class="ranking-top-item">
						<div class="ranking-portrait">
							<!-- <img :src="rankingList[1]?.buddha" class="ranking-portrait-img ranking-two-bow"/> -->
							<img src="../../assets/shop-img.jpg" class="ranking-portrait-img ranking-two-bow"/>
							<img src="../../assets/author/huang-two.png" class="ranking-portrait-guan"/>
						</div>
					</div>
				</div>
				
				<div class="ranking-one-item">
					<img  src="../../assets/author/pai-one.png" class="ranking-one-img"/>
					<div class="ranking-top-item">
						<div class="ranking-portrait">
							<img src="../../assets/shop-img.jpg" class="ranking-portrait-img ranking-one-bow"/>
							<img src="../../assets/author/huang-one.png" class="ranking-portrait-guan"/>
						</div>
					</div>
				</div>
				
				<div class="ranking-san-item">
					<img  src="../../assets/author/pai-san.png" class="ranking-san-img"/>
					<div class="ranking-top-item">
						<div class="ranking-portrait">
							<img src="../../assets/shop-img.jpg" class="ranking-portrait-img ranking-san-bow"/>
							<img src="../../assets/author/huang-san.png" class="ranking-portrait-guan"/>
						</div>
					</div>
				</div>
			</div>
			
			<div class="ranking-list-item" v-for="(value,index) in 6" :key="index">
				<div class="ranking-list-num">{{index+4}}</div>
				<img src="../../assets/shop-img.jpg" class="ranking-list-user"/>
				<div class="ranking-list-name">FENzi爱分享</div>
			</div>
		</div>
		<div class="author-main-ret">
			<div class="author-ret-list" v-for="(item,key) in 10" :key="key">
				<div class="author-list-top">
					<div class="author-list-port">
						<img src="../../assets/shop-img.jpg" class="list-portrait-img" :class="{'list-one':key+1==1,'list-two':key+1==2,'list-san':key+1==3,'list-mo':key+1>3}"/>
						<img  src="../../assets/author/huang-one.png" class="list-portrait-guan" v-if="key+1==1"/>
						<img  src="../../assets/author/huang-two.png" class="list-portrait-guan" v-if="key+1==2"/>
						<img  src="../../assets/author/huang-san.png" class="list-portrait-guan" v-if="key+1==3"/>
					</div>
					<div class="author-list-info">
						<div class="author-list-name">罗氏豆腐</div>
						<div class="author-list-text">壁纸业余爱好者</div>
					</div>
					<div class="follow-btn gestures">关注他</div>
				</div>
				<el-row :gutter="11" style="margin-top: 16px;">
				  <el-col :span="8" v-for="(value,index) in 3" :key="index">
					  <div class="author-list-works" >
						  <img src="../../assets/shop-img.jpg" class="author-works-img"/>
						  <div class="author-works-top">
							  <div class="author-works-tel">原神天花板美女</div>
							  <img src="../../assets/wall-vip-icon.png" class="author-works-vip"/>
						  </div>
					  </div>
				  </el-col>
				</el-row>
			</div>
		</div>
  </div>
</template>

<script>
	import {rankingUserList,rankingPeriodList} from "@/api/index.js"
	export default {
		data(){
			return {
				rankingList:[],
				choiceShow:false
			}
		},
		created() {
			this.getRankingUserList()
			this.getRankingPeriodList()
		},
		methods:{
			getRankingUserList(){
				rankingUserList('2023-04').then(res=>{
					this.rankingList = res.data
				})
			},
			getRankingPeriodList(){
				rankingPeriodList().then(res=>{
					console.log(res)
				})
			},
			changeChoice(){
				this.choiceShow = !this.choiceShow
			},
		}
	}
</script>
<style lang="scss">
	.date-choice-option{
		display: flex;
		align-items: center;
		padding: 10px 36px;
		.date-choice-label{
			color: #B8B9BC;
			font-size: 14px;
			span{
				font-size: 18px;
				margin: 0px 3px;
			}
		}
		.date-choice-text{
			color: #84848A;
			font-size: 14px;
			margin-left: 10px;
		}
	}
	.author-list-works{
		height: 114px;
		width: 100%;
		position: relative;
		.author-works-img{
			height: 114px;
			width: 100%;
		}
		.author-works-top{
			position: absolute;
			top: 6px;
			left: 6px;
			right: 6px;
			display: flex;
			align-items: center;
			.author-works-tel{
				color: #FFFFFF;
				font-size: 12px;
			}
			.author-works-vip{
				margin-left: auto;
				width: 30px;
				height: 17px;
			}
		}
	}
	.author-main-ret{
		flex: 1;
		padding: 0px 20px;
		overflow: auto;
		.author-ret-list{
			margin-top: 20px;
			border-bottom: 1px solid #333538;
			padding-bottom: 20px;
			.author-list-top{
				display: flex;
				align-items: center;
				.author-list-port{
					width: 56px;
					height: 56px;
					border-radius: 50%;
					position: relative;
					.list-portrait-img{
						width: 54px;
						height: 54px;
						border-radius: 50%;
						
					}
					.list-one{
						border: 2px solid #FF3C70;
					}
					.list-two{
						border: 2px solid #A93CFF;
					}
					.list-san{
						border: 2px solid #FF9A23;
					}
					.list-mo{
						border: 2px solid #FFFFFF;
					}
					.list-portrait-guan{
						position: absolute;
						width: 23px;
						height: 22px;
						top: -7px;
						left: -4px;
						
					}
				}
				.author-list-info{
					margin-left: 18px;
					.author-list-name{
						color: #FFFFFF;
						font-size: 14px;
						font-weight: bold;
					}
					.author-list-text{
						margin-top: 6px;
						color: #7B7B81;
						font-size: 12px;
					}
				}
				.follow-btn{
					margin-left: auto;
					width: 84px;
					height: 30px;
					text-align: center;
					line-height: 30px;
					background-color: #0EAAF3;
					border-radius: 15px;
					color: #FFFFFF;
					font-size: 14px;
				}
			}
		}
	}
	.ranking-list-item{
		margin-top: 30px;
		display: flex;
		align-items: center;
		.ranking-list-num{
			color: #FFFFFF;
			font-size: 30px;
		}
		.ranking-list-user{
			margin-left: 15px;
			width: 46px;
			height: 46px;
			border-radius: 50%;
			border: 2px solid #FFFFFF;
		}
		.ranking-list-name{
			margin-left: 17px;
			color: #B8B9BC;
			font-size: 14px;
			font-weight: bold;
		}
	}
	.author-main{
		min-height: calc(100vh - 72px);
		background-color: #24272A;
		display: flex;
		// align-items: center;
		position: fixed;
		top: 72px;
		left: 180px;
		right: 0;
		bottom: 0;
		.author-main-left{
			width: 340px;
			border-right: 1px solid #333538;
			padding: 20px 21px 0px 30px;
			.author-left-top{
				display: flex;
				align-items: center;
				.author-bangdan-img{
					width: 230px;
					height: 58px;
				}
				.author-top-ret{
					display: flex;
					align-items: center;
					margin-left: auto;
					.author-shuoming-img{
						width: 12px;
						height: 12px;
					}
					.author-shuoming-text{
						margin-left: 6px;
						color: #7C7C82;
						font-size: 12px;
					}
				}
			}
		}
	}
	.date-choice-item{
		margin-top: 14px;
		background-color: #2F3235;
		border-radius: 6px;
		padding: 11px 12px;
		display: flex;
		align-items: center;
		position: relative;
		.date-choice-list{
			position: absolute;
			left: 0;
			right: 0;
			top: 50px;
			height: 232px;
			background: #2F3235;
			border-radius: 6px;
			z-index: 99;
			overflow-y: auto;
		}
		.date-choice-img{
			width: 18px;
			height: 18px;
		}
		.date-choice-stage{
			margin-left: 8px;
			color: #B8B9BC;
			font-size: 14px;
			.stage-val{
				font-size: 18px;
				font-weight: bold;
				margin: 0px 3px;
			}
		}
		.date-choice-vals{
			margin-left: 10px;
			color: #84848A;
			font-size: 14px;
		}
		.date-choice-down{
			margin-left: auto;
			width: 12px;
			height: 7px;
		}
	}
	.ranking-topthree-item{
		display: flex;
		margin-top: 56px;
		align-items: baseline;
		padding: 0px 23px 0px 5px;
		// justify-content: baseline;
		.ranking-two-item{
			position: relative;
		}
		.ranking-two-img{
			width: 100px;
			height: 110px;
		}
		
	}
	.ranking-one-item{
		margin-left: 10px;
		width: 100px;
		height: 140px;
		position: relative;
		.ranking-one-img{
			width: 100px;
			height: 140px;
		}
	}
	.ranking-top-item{
		position: absolute;
		left: 0;
		right: 0;
		top: -18px;
		display: flex;
		justify-content: center;
		.ranking-portrait{
			width: 54px;
			height: 54px;
			border-radius: 50%;
			position: relative;
			.ranking-portrait-img{
				width: 54px;
				height: 54px;
				border-radius: 50%;
				
			}
			.ranking-two-bow{
				border: 2px solid #A93CFF;
			}
			.ranking-one-bow{
				border: 2px solid #FF3C70;
			}
			.ranking-san-bow{
				border: 2px solid #FF9A23;
			}
			.ranking-portrait-guan{
				position: absolute;
				width: 23px;
				height: 22px;
				top: -7px;
				left: -4px;
			}
		}
	}
	.ranking-san-item{
		margin-left: 10px;
		width: 100px;
		height: 98px;
		position: relative;
		.ranking-san-img{
			width: 100px;
			height: 98px;
		}
	}
</style>
