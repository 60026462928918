<template>
	<div>
		<div class="author-nav-main">
			<div class="author-nav-back gestures">
				<img src="../../assets/back-icons.png"/>
				<span class="back-text">返回</span>
			</div>
			<div class="author-user-item">
				<img src="../../assets/shop-img.jpg" class="user-heads"/>
				<div class="author-user-info">
					<div class="author-user-name">阡陌初心</div>
					<div class="author-user-text">高清优质动态壁纸创作，为你的电脑带来视觉上的享受~</div>
					<div class="author-user-tips">暂无个人主页</div>
				</div>
				<div class="author-user-ret">
					<div class="author-user-other">
						<div class="author-other-item">
							<div class="other-item-text">作品数</div>
							<div class="other-item-val">1364</div>
						</div>
						<div class="author-other-item">
							<div class="other-item-text">获赞数</div>
							<div class="other-item-val">25.1万</div>
						</div>
						<div class="author-other-item">
							<div class="other-item-text">关注数</div>
							<div class="other-item-val">64</div>
						</div>
						<div class="author-other-item">
							<div class="other-item-text">粉丝数</div>
							<div class="other-item-val">1.6万</div>
						</div>
					</div>
					<div class="author-ret-boom">
						<div class="author-ret-btn gestures">关注</div>
					</div>
				</div>
			</div>
		</div>
		
		<div class="author-tabs">
			<div class="author-tab-item gestures">动态壁纸 (1364)</div>
			<div class="author-tab-line">丨</div>
			<div class="author-tab-item gestures">静态壁纸 0)</div>
		</div>
		
		
		<productItem></productItem>
		
	</div>
</template>

<script>
	import productItem from "@/components/productItem.vue"
	export default {
		components:{productItem},
		data() {
			return {
				
			}
		}
	}
</script>

<style lang="scss">

.author-tabs{
	margin-top: 18px;
	display: flex;
	align-items: center;
	justify-content: center;
	.author-tab-item{
		color: #8A9297;
		font-size: 12px;
	}
	.author-tab-line{
		color: #727C8E;
		font-size: 12px;
		margin: 0px 18px;
	}
}
.author-nav-main{
	background: #F7F8F8;
	border-radius: 6px;
	margin: 16px 16px 0px 16px;
	padding: 14px;
}
.author-nav-back{
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #E3E5E8;
	border-radius: 5px;
	height: 28px;
	width: 55px;
	img{
		width: 11px;
		height: 8px;
	}
	.back-text{
		color: #545454;
		font-size: 12px;
		margin-left: 4px;
	}
}
.author-user-item{
	display: flex;
	align-items: center;
	padding: 0px 62px 30px 80px;
	.user-heads{
		width: 80px;
		height: 80px;
		border-radius: 50%;
	}
	.author-user-info{
		margin-left: 20px;
		.author-user-name{
			color: #2D3135;
			font-size: 16px;
		}
		.author-user-text{
			color: #7A839C;
			font-size: 12px;
			margin-top: 6px;
		}
		.author-user-tips{
			color: #7A839C;
			font-size: 12px;
			margin-top: 6px;
		}
	}
	.author-user-ret{
		margin-left: auto;
		.author-user-other{
			display: flex;
			align-items: center;
			.author-other-item{
				margin-left: 32px;
			}
			.other-item-text{
				color: #727C8E;
				font-size: 14px;
			}
			.other-item-val{
				text-align: center;
				margin-top: 4px;
				color: #2D3135;
				font-size: 14px;
				font-weight: bold;
			}
		}
		.author-ret-boom{
			display: flex;
			align-items: center;
			justify-content: center;
			margin-top: 16px;
			.author-ret-btn{
				width: 100px;
				height: 34px;
				text-align: center;
				line-height: 34px;
				color: #FFFFFF;
				font-size: 14px;
				background-color: #0EAAF3;
				border-radius: 6px;
			}
		}
	}
}
</style>