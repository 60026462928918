<template>
	<div class="page-box">
		<div class="home-tab-main">
			<div class="home-tab-list">
				<div class="home-tab-item gestures" :class="{'home-tab-sel':homeTabIndex==index}" 
				v-for="(value,index) in homeTab" :key="index" @click="changeHomes(index)">{{value.label}}</div>
			</div>
		</div>
		<div style="height: 66px;"></div>
		<el-row :gutter="12" style="margin-top: 16px;">
			<el-col :span="8">
				 <div v-for="(value,index) in wallpaperData" :key="index">
					<div class="home-banner-ret gestures"  :class="{'ma-12':index === 1}" v-if="(index+1) <= 2" 
					@mouseover="mouseOverGift(index,true)"  @mouseleave="mouseOverGift(index,false)" @click="goDetails">
						<img :src="value.surfacePlot" class="wallpape-img"/>
						<div class="head-item">
							<div>{{value.name}}</div>
							<img src="../../assets/wall-vip-icon.png"/>														 
						</div>
						<div class="wallpape-boom" v-show="value.sel">
							<div class="wallpape-boom-img">
								<!-- <img :src="value.buddha"/> -->
								<img src="../../assets/no-login-img.png"/>
							</div>
							<!-- {{value.nickname}} -->
							<div class="wallpape-boom-name">测试账号</div>
							<div class="wallpape-boom-btn">设为壁纸</div>														 
						</div>
					</div> 
				 </div>
				 
				 <!-- <div class="home-banner-ret" style="margin-top: 12px;"></div> -->
			 </el-col>	
			 <el-col :span="16">
			 	<div class="home-banner gestures" @click="goDetails">
			 		<img :src="hotBotInfo.surfacePlot" class="home-banner-img"/>
			 			<div class="home-banner-info">
			 				<div class="wall-title">{{hotBotInfo.name}}</div>
			 				<img src="../../assets/wall-vip-icon.png" class="wall-vip-img"/>														 
			 			</div>
			 			<!-- <div class="home-banner-boom">
			 				<img src="../../assets/renbang-home.png" class="boom-ren-img"/>
			 				<div class="banner-boom-num gestures" v-for="(value,index) in hotBotList.length" :key="index" 
			 				:class="{'banner-boom-sel':bannerIndex==index}" @click="changeBotIndex(index)">{{index+1}}</div>														 
			 			</div> -->
			 		</div>
			 	</el-col>			
		</el-row>
		
		<el-row :gutter="12">
			<div v-for="(value,index) in wallpaperData" :key="index" >
				<el-col :span="8" >
					<div class="home-banner-ret gestures" style="margin-top: 12px;"  :class="{'ma-12':index === 1}" v-if="(index+1) > 2" 
					@mouseover="mouseOverGift(index,true)"  @mouseleave="mouseOverGift(index,false)" @click="goDetails">
					 	<img :src="value.surfacePlot" class="wallpape-img"/>
					 	<div class="head-item">
					 		<div>{{value.name}}</div>
					 		<img src="../../assets/wall-vip-icon.png"/>														 
					 	</div>
					 	<div class="wallpape-boom" v-show="value.sel">
					 		<div class="wallpape-boom-img">
					 			<!-- <img :src="value.buddha"/> -->
								<img src="../../assets/no-login-img.png"/>
					 		</div>
							<!-- {{value.nickname}} -->
					 		<div class="wallpape-boom-name">测试账号</div>
					 		<div class="wallpape-boom-btn">设为壁纸</div>														 
					 	</div>
					</div> 
				</el-col>
			</div>
		</el-row>
		
	</div>
</template>

<script>
	import member from "@/components/member.vue"
	export default {
		components:{member},
		data(){
			return {
				homeTab:[
					{label:'精选'},
					{label:'最新'},
					{label:'4K'},
					{label:'风景'},
				],
				homeTabIndex:0,
				wallpaperData:[
					{surfacePlot:'https://img-baofun.zhhainiao.com/market/133/333555.jpg',name:'多彩电竞女孩',sel:false},
					{surfacePlot:'https://img-baofun.zhhainiao.com/market/133/366476.jpg',name:'唯美花园少女',sel:false},
					{surfacePlot:'https://img-baofun.zhhainiao.com/market/133/357269.jpg',name:'4K黑龙少女',sel:false},
					{surfacePlot:'https://img-baofun.zhhainiao.com/market/5/pengke9.jpg',name:'朋克少女',sel:false},
					{surfacePlot:'https://img-baofun.zhhainiao.com/market/5/tutu4.jpg',name:'平行世界门',sel:false},
					{surfacePlot:'https://img-baofun.zhhainiao.com/market/5/maomi.jpg',name:'慵懒的猫咪',sel:false},
				],
				hotBotInfo:{
					surfacePlot:'https://img-baofun.zhhainiao.com/market/133/333555.jpg',name:'多彩电竞女孩'
				},
			}
		},
		created() {
			
		},
		methods: {
			changeHomes(index){
				if(this.homeTabIndex!=index){
					this.homeTabIndex=index
				}
			},
			mouseOverGift(index,type){
				this.wallpaperData[index].sel = type
			},
			goDetails(){
				this.$router.push({ name: 'details'});
			},
		}
	}
</script>

<style lang="scss">
	.page-box{
		padding: 0px 16px;
	}
	.head-item{
		position: absolute;
		top: 8px;
		left: 8px;
		right: 8px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		color: #FFFFFF;
		font-size: 12px;
		img{
			width: 30px;
			height: 17px;
		}
	}
	.ma-12{
		margin-top: 12px;
	}
	.wallpape-img{
		width: 100%;
		height: 200px;
	}
	.home-tab-main{
		position: fixed;
		top: 72px;
		left: 180px;
		right: 0;
		z-index: 99;
		background-color: #ffffff;
		padding: 0px 16px;
	}
	.home-tab-list{
		margin-top: 16px;
		display: flex;
		align-items: center;
		background-color: #F2F4F5;
		border-radius: 6px;
		height: 50px;
		
		// padding-left: 9px;
		.home-tab-item{
			margin-left: 9px;
			position: relative;
			height: 32px;
			line-height: 32px;
			padding: 0px 16px;
			color: #333333;
			font-size: 14px;
			font-weight: bold;
		}
		.home-tab-sel{
			background-color: #0EAAF3;
			box-shadow: 0px 3px 6px 1px rgba(14,170,243,0.3);
			border-radius: 16px 16px 16px 16px;
			color: #FFFFFF;
		}
	}
	
	.home-banner{
		height: 412px;
		position: relative;
		// background-color: red;
		.home-banner-img{
			width: 100%;
			height: 412px;
		}
		.home-banner-boom{
			position: absolute;
			bottom: 9px;
			left: 12px;
			display: flex;
			align-items: center;
			.boom-ren-img{
				width: 82px;
				height: 30px;
			}
			.banner-boom-num{
				color: #ffffff;
				font-size: 12px;
				padding: 2px 5px;
				border-radius: 2px;
				background-color: #000000;
				margin-left: 6px;
				margin-top: 2px;
			}
			.banner-boom-sel{
				background-color: #0EAAF3;
				box-shadow: 0px 3px 6px 1px rgba(14,170,243,0.5);
			}
		}
	}
	.home-banner-info{
		position: absolute;
		top: 9px;
		left: 0;
		right: 0;
		padding: 0px 9px;
		display: flex;
		align-items: center;
		.wall-title{
			color: #FFFFFF;
			font-size: 12px;
		}
		.wall-vip-img{
			width: 30px;
			height: 17px;
			margin-left: auto;
		}
	}
	.home-banner-ret{
		height: 200px;
		// background-color: red;
		position: relative;
	}
	.el-col-items{
		height: 200px;
		background-color: red;
		width: 100%;
	}
	.wallpape-boom-img{
		width: 25px;
		height: 25px;
		img{
			width: 25px;
			height: 25px;
			border-radius: 50%;
			border: 2px solid #FFFFFF;
		}
	}
	.wallpape-boom{
		position: absolute;
		left: 10px;
		right: 10px;
		bottom: 8px;
		display: flex;
		align-items: center;
		.wallpape-boom-name{
			color: #FFFFFF;
			font-size: 14px;
			margin-left: 6px;
		}
		.wallpape-boom-btn{
			margin-left: auto;
			width: 86px;
			height: 28px;
			text-align: center;
			line-height: 28px;
			background: #0EAAF3;
			box-shadow: 0px 3px 6px 1px rgba(14,170,243,0.3);
			border-radius: 4px;
			color: #FFFFFF;
			font-size: 14px;
		}
	}
</style>