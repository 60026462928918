<template>
	<div>
		<el-row :gutter="12" style="padding: 6px 0px;">
		  <el-col :span="8" style="margin-top: 12px;" v-for="(value,index) in dataList" :key="index">
			<div class="author-goods-item gestures" @mouseover="mouseOverGift(index,true)"  @mouseleave="mouseOverGift(index,false)" @click="goDetails">
				<img :src="value.surfacePlot" class="author-goods-img"/>
				<div class="author-goods-nav">
					<div class="author-goods-tel">{{value.name}}</div>
					<img src="../assets/wall-vip-icon.png"/>
				</div>
				<div class="author-goods-boom" v-show="value.sel">
					<img src="../assets/no-login-img.png"/>
					<div class="goods-user-name">测试账号</div>
					<div class="goods-user-btn gestures">设为壁纸</div>
				</div>
			</div>
		  </el-col>
		</el-row>
		
	</div>
</template>

<script>
	export default {
		data() {
			return {
				dataList:[
					{surfacePlot:'https://img-baofun.zhhainiao.com/market/133/333555.jpg',name:'多彩电竞女孩',sel:false},
					{surfacePlot:'https://img-baofun.zhhainiao.com/market/133/366476.jpg',name:'唯美花园少女',sel:false},
					{surfacePlot:'https://img-baofun.zhhainiao.com/market/133/357269.jpg',name:'4K黑龙少女',sel:false},
					{surfacePlot:'https://img-baofun.zhhainiao.com/market/5/pengke9.jpg',name:'朋克少女',sel:false},
					{surfacePlot:'https://img-baofun.zhhainiao.com/market/5/tutu4.jpg',name:'平行世界门',sel:false},
					{surfacePlot:'https://img-baofun.zhhainiao.com/market/5/maomi.jpg',name:'慵懒的猫咪',sel:false},
				]
			}
		},
		methods:{
			mouseOverGift(index,type){
				this.dataList[index].sel = type
			},
			goDetails(){
				this.$router.push({ name: 'details'});
			},
		}
	}
</script>

<style lang="scss">
	.author-goods-item{
		width: 100%;
		height: 200px;
		position: relative;
		border-radius: 2px;
		.author-goods-img{
			width: 100%;
			height: 200px;
			border-radius: 2px;
		}
		.author-goods-nav{
			position: absolute;
			top: 9px;
			right: 8px;
			left: 10px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			.author-goods-tel{
				color: #FFFFFF;
				font-size: 12px;
			}
			img{
				width: 30px;
				height: 17px;
			}
		}
		.author-goods-boom{
			position: absolute;
			bottom: 7px;
			right: 10px;
			left: 10px;
			display: flex;
			align-items: center;
			img{
				width: 26px;
				height: 26px;
				border-radius: 50%;
			}
			.goods-user-name{
				margin-left: 7px;
				color: #FFFFFF;
				font-size: 14px;
			}
			.goods-user-btn{
				margin-left: auto;
				width: 86px;
				height: 28px;
				text-align: center;
				line-height: 28px;
				box-shadow: 0px 3px 6px 1px rgba(14,170,243,0.3);
				border-radius: 4px 4px 4px 4px;
				background-color: #0EAAF3;
				color: #FFFFFF;
				font-size: 14px;
			}
		}
	}
</style>