import http from "../utils/axios"
import config from "../config"
import jsonJoin from "../utils/jsonJoin"

// 获取套餐信息
const getPackageListApi = () => {
  return http.get(
    `${config.qnwProjectDomain}/api/price_plan?soft_id=${config.soft_id}`
  )
}

const getPayCodeApi = (plan_type, goods_id) => {
  const params = {
    curr_ver: config.curr_ver,
    soft_id: config.soft_id,
    mac_code: config.mac_code,
    device_code: config.device_code,
    os: config.os,
    source: config.source,
    mp: config.mp,
    bdVid: config.bdVid,
    strategy: config.strategy,
    device_id: config.device_id,
    ekey: config.ekey,
    plan_type: plan_type,
    goods_id: goods_id,
  }
  return http.get(
    `${config.wxLoginAndPayDomain}/api/wechat-qr?${jsonJoin(params)}`
  )
}

const getPayCodeUrlApi = (plan_type, goods_id) => {
  const data = {
    curr_ver: config.curr_ver,
    soft_id: config.soft_id,
    mac_code: config.mac_code,
    device_code: config.device_code,
    os: config.os,
    source: config.source,
    mp: config.mp,
    bdVid: config.bdVid,
    strategy: config.strategy,
    device_id: config.device_id,
    ekey: config.ekey,
    plan_type: plan_type,
    goods_id: goods_id,
    redirect_url: window.location.href,
  }
  const params = {
    soft_id: config.soft_id,
    ekey: config.ekey,
    source: config.source,
  }
  return http.post(
    `${config.wxLoginAndPayDomain}/api/order?${jsonJoin(params)}`,
    data
  )
}

const confirmPayInfoApi = (order_id) => {
  return http.get(
    `${config.wxLoginAndPayDomain}/api/verify-buy?order_id=${order_id}`
  )
}

export { getPackageListApi, getPayCodeApi, getPayCodeUrlApi, confirmPayInfoApi }
