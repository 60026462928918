<template>
	<div class="page-mains">
		<div class="details-nav-item">
			<div class="nav-back-item gestures" @click="goBack">
				<img src="../../assets/back-icons.png"/>
				<span>返回</span>
			</div>
			<div class="details-nav-title">朋克少女</div>
		</div>
		<el-row :gutter="12" style="margin-top: 16px;">
		  <el-col :span="18">
			  <div class="details-main-left">
				  <img src="https://img-baofun.zhhainiao.com/market/133/333555.jpg" class="details-img"/>
				  <div class="details-main-nav">
					  <img src="../../assets/no-login-img.png" class="details-user-haed"/>
					  <div class="details-user-name">测试用户</div>
					  <div class="details-follow-btn">关注</div>
					  <img src="../../assets/wall-vip-icon.png" class="details-vip-icon"/>
				  </div>
			  </div>
			  <div class="paper-info">
				  <div>
					  <div class="paper-info-item">
						<div class="paper-info-label">分辨率</div>
						<div class="paper-info-text">1920*1080</div>
					  </div>
					  <div class="paper-info-item" style="margin-top: 16px;">
					  	<div class="paper-info-label">相关标签</div>
						<div class="paper-info-tag">互动</div>
						<div class="paper-info-tag">赛博朋克</div>
						<div class="paper-info-tag">粒子互动</div>
					  </div>
				  </div>
				  <div class="paper-info-ret gestures">
					  <img src="../../assets/like-icon.png"/>
					  <div class="info-ret-text">64人赞了改壁纸</div>
				  </div>
			  </div>
			  
			  <div class="paper-info-boom">
				  <div class="paper-boom-item gestures">
					  <img src="../../assets/preview-icon.png"/>
					  <span>在桌面预览</span>
				  </div>
				  <div class="paper-boom-item gestures">
				  	<img src="../../assets/screen-icon.png"/>
				  	<span>设为锁屏</span>
				  </div>
				  <div class="paper-boom-btn gestures" @click="useWallpaper">使用壁纸</div>
			  </div>
		  </el-col>
		  <el-col :span="6">
			<img src="../../assets/chatgpt-code.png" class="spread-img"/>
			
			<div class="recommend-title">推荐壁纸</div>
			
			<div class="recommend-list gestures" v-for="(value,index) in dataList" :key="index" @mouseover="mouseOverGift(index)"  @mouseleave="mouseLeaveGift(index)">
				<img :src="value.surfacePlot" class="recommend-list-img"/>
				<div class="recommend-list-nav">
					<div class="recommend-list-name">{{value.name}}</div>
					<img src="../../assets/wall-vip-icon.png"/>
				</div>
				<div class="recommend-list-mask" v-show="index == recommendIndex">
					<div class="list-mask-btn">设为壁纸</div>
				</div>
			</div>
		  </el-col>
		</el-row>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				recommendList:[
					{text:'Dronies NFT Illustrations'},
					{text:'"Follow the call"'},
					{text:'Galaxy Desert Landscape'},
				],
				recommendIndex:-1,
				dataList:[
					{surfacePlot:'https://img-baofun.zhhainiao.com/market/5/pengke9.jpg',name:'朋克少女',sel:false},
					{surfacePlot:'https://img-baofun.zhhainiao.com/market/5/tutu4.jpg',name:'平行世界门',sel:false},
					{surfacePlot:'https://img-baofun.zhhainiao.com/market/5/maomi.jpg',name:'慵懒的猫咪',sel:false},
				]
			}
		},
		methods:{
			mouseOverGift(index){
				if(this.recommendIndex != index){
					this.recommendIndex = index
				}
			},
			mouseLeaveGift(){
				this.recommendIndex = -1
			},
			goBack(){
				this.$router.back()
			},
			useWallpaper(){
				window.sendMessage(17,{url:this.dataList[0].surfacePlot,name:'朋克少女'})
			},
		}
	}
</script>

<style lang="scss">
	.recommend-title{
		margin-top: 20px;
		padding-bottom: 2px;
		color: #000000;
		font-size: 14px;
		font-weight: bold;
	}
	.recommend-list-mask{
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 4px;
		background-color: rgba(0,0,0,0.5);
		.list-mask-btn{
			width: 86px;
			height: 28px;
			text-align: center;
			line-height: 28px;
			color: #FFFFFF;
			font-size: 14px;
			background-color: #0EAAF3;
			border-radius: 4px;
			box-shadow: 0px 3px 6px 1px rgba(14,170,243,0.3);
		}
	}
	.recommend-list{
		margin-top: 10px;
		position: relative;
		width: 100%;
		height: 136px;
		border-radius: 4px;
		.recommend-list-img{
			width: 100%;
			height: 136px;
			border-radius: 4px;
		}
		.recommend-list-nav{
			position: absolute;
			top: 10px;
			right: 8px;
			left: 10px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			.recommend-list-name{
				color: #FFFFFF;
				font-size: 12px;
			}
			img{
				width: 30px;
				height: 17px;
			}
		}
	}
	.spread-img{
		width: 100%;
		height: 136px;
	}
	.page-mains{
		padding: 16px;
	}
	.paper-info-boom{
		margin-top: 24px;
		display: flex;
		align-items: center;
		justify-content: center;
		.paper-boom-item{
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 0px 9px;
			height: 32px;
			border-radius: 4px;
			border: 1px solid #E1E1E1;
			box-shadow: 0px 3px 6px 1px rgba(14,170,243,0.2);
			margin: 0px 4px;
			img{
				width: 14px;
				height: 14px;
			}
			span{
				margin-left: 6px;
				color: #999999;
				font-size: 13px;
			}
		}
		.paper-boom-btn{
			margin-left: 4px;
			width: 98px;
			height: 32px;
			text-align: center;
			line-height: 32px;
			background-color: #0EAAF3;
			box-shadow: 0px 3px 6px 1px rgba(14,170,243,0.2);
			border-radius: 4px;
			color: #FFFFFF;
			font-size: 13px;
		}
	}
	.paper-info-ret{
		text-align: center;
		img{
			width: 22px;
			height: 20px;
		}
		.info-ret-text{
			margin-top: 4px;
			color: #828A9A ;
			font-size: 12px;
		}
	}
	.paper-info{
		margin-top: 10px;
		background-color: #F9F9F9;
		border-radius: 4px;
		padding: 18px 30px 20px 22px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		.paper-info-item{
			display: flex;
			align-items: center;
			.paper-info-label{
				color: #000000;
				font-size: 12px;
				font-weight: bold;
			}
			.paper-info-text{
				margin-left: 24px;
				color: #000000;
				font-size: 12px;
			}
			.paper-info-tag{
				margin-left: 8px;
				padding: 2px 10px;
				background-color: #ededed;
				border-radius: 10px;
				color: #7E7E7E;
				font-size: 12px;
			}
		}
	}
	.details-main-left{
		width: 100%;
		height: 472px;
		position: relative;
		.details-img{
			width: 100%;
			height: 472px;
		}
		.details-main-nav{
			display: flex;
			align-items: center;
			position: absolute;
			top: 9px;
			right: 16px;
			left: 18px;
			.details-user-haed{
				width: 34px;
				height: 34px;
				border-radius: 50%;
			}
			.details-user-name{
				margin-left: 10px;
				color: #FFFFFF;
				font-size: 14px;
			}
			.details-follow-btn{
				width: 56px;
				height: 24px;
				text-align: center;
				line-height: 24px;
				background-color: #0EAAF3;
				border-radius: 3px;
				margin-left: 14px;
				color: #FFFFFF;
				font-size: 12px;
			}
			.details-vip-icon{
				margin-left: auto;
				width: 30px;
				height: 17px;
			}
		}
	}
	.details-nav-item{
		display: flex;
		align-items: center;
		.nav-back-item{
			display: flex;
			align-items: center;
			width: 56px;
			height: 28px;
			background-color: #F7F8F8;
			border-radius: 5px;
			justify-content: center;
			img{
				width: 12px;
				height: 9px;
			}
			span{
				color: #545454;
				font-size: 12px;
				margin-left: 4px;
			}
		}
		.details-nav-title{
			margin-left: 18px;
			color: #000000;
			font-size: 16px;
			font-weight: bold;
		}
	}
</style>