<template>
  <div class="page-box">
	  <div class="home-tab-main">
		  <div class="home-tab-list">
		  	<div class="home-tab-item gestures" :class="{'home-tab-sel':homeTabIndex==index}" 
		  	v-for="(value,index) in homeTab" :key="index" @click="changeHomes(index)">{{value.label}}</div>
		  </div>
	  </div>
       <div style="height: 66px;"></div>
	   <el-row :gutter="12" style="margin-top: 16px;">
	     <el-col :span="16">
			 <div class="home-banner gestures" @click="goDetails">
				 <img :src="hotBotInfo.surfacePlot" class="home-banner-img"/>
				 <div class="home-banner-info">
					 <div class="wall-title">{{hotBotInfo.name}}</div>
					 <img src="../assets/wall-vip-icon.png" class="wall-vip-img"/>
				 </div>
				 <div class="home-banner-boom">
					 <img src="../assets/renbang-home.png" class="boom-ren-img"/>
					 <div class="banner-boom-num gestures" v-for="(value,index) in wallpaperData.length" :key="index" 
					 :class="{'banner-boom-sel':bannerIndex==index}" @click="changeBotIndex(index)">{{index+1}}</div>
				 </div>
			 </div>
		 </el-col>
	     <el-col :span="8">
			 <div v-for="(value,index) in wallpaperData" :key="index">
				<div class="home-banner-ret gestures"  :class="{'ma-12':index === 1}" v-if="(index+1) <= 2" 
				@mouseover="mouseOverGift(index,true)"  @mouseleave="mouseOverGift(index,false)" @click="goDetails">
					<img :src="value.surfacePlot" class="wallpape-img"/>
					<div class="head-item">
						<div>{{value.name}}</div>
						<img src="../assets/wall-vip-icon.png"/>														 
					</div>
					<div class="wallpape-boom" v-show="value.sel">
						<div class="wallpape-boom-img">
							<!-- <img :src="value.buddha"/> -->
							<img src="../assets/no-login-img.png"/>
						</div>
						<!-- {{value.nickname}} -->
						<div class="wallpape-boom-name">测试账号</div>
						<div class="wallpape-boom-btn">设为壁纸</div>														 
					</div>
				</div> 
			 </div>
			 
			 <!-- <div class="home-banner-ret" style="margin-top: 12px;"></div> -->
		 </el-col>
	   </el-row>
	   
	   <el-row :gutter="12">
		   <div v-for="(value,index) in wallpaperData" :key="index" >
			<el-col :span="8" >
				<div class="home-banner-ret gestures" style="margin-top: 12px;"  :class="{'ma-12':index === 1}" v-if="(index+1) > 2" 
				@mouseover="mouseOverGift(index,true)"  @mouseleave="mouseOverGift(index,false)" @click="goDetails">
				 	<img :src="value.surfacePlot" class="wallpape-img"/>
				 	<div class="head-item">
				 		<div>{{value.name}}</div>
				 		<img src="../assets/wall-vip-icon.png"/>														 
				 	</div>
				 	<div class="wallpape-boom" v-show="value.sel">
				 		<div class="wallpape-boom-img">
				 			<!-- <img :src="value.buddha"/> -->
							<img src="../assets/no-login-img.png"/>
				 		</div>
						<!-- {{value.nickname}} -->
				 		<div class="wallpape-boom-name">测试账号</div>
				 		<div class="wallpape-boom-btn">设为壁纸</div>														 
				 	</div>
				</div> 
			</el-col>
		</div>
	   </el-row>
	   
  </div>
</template>

<script>
	import {wallpaperClassify,hotBotWallpaper,wallpaperList} from "@/api/index.js"
	import qs from 'qs'
	export default {
		data(){
			return {
				homeTab:[
					{label:'美女'},
					{label:'电竞'},
					{label:'游戏'},
					{label:'风景'},
				],
				homeTabIndex:0,
				bannerIndex:0,
				hotBotList:[],
				hotBotInfo:{
					surfacePlot:'https://img-baofun.zhhainiao.com/market/133/333555.jpg',name:'多彩电竞女孩'
				},
				wallpaperData:[
					{surfacePlot:'https://img-baofun.zhhainiao.com/market/133/333555.jpg',name:'多彩电竞女孩',sel:false},
					{surfacePlot:'https://img-baofun.zhhainiao.com/market/133/366476.jpg',name:'唯美花园少女',sel:false},
					{surfacePlot:'https://img-baofun.zhhainiao.com/market/133/357269.jpg',name:'4K黑龙少女',sel:false},
					{surfacePlot:'https://img-baofun.zhhainiao.com/market/5/pengke9.jpg',name:'朋克少女',sel:false},
					{surfacePlot:'https://img-baofun.zhhainiao.com/market/5/tutu4.jpg',name:'平行世界门',sel:false},
					{surfacePlot:'https://img-baofun.zhhainiao.com/market/5/maomi.jpg',name:'慵懒的猫咪',sel:false},
				]
			}
		},

		created() {
			// this.getWallpaperClassify()
			// this.getHotBotWallpaper()
			
		},
		methods:{
			goDetails(){
				this.$router.push({ name: 'details'});
			},
			mouseOverGift(index,type){
				this.wallpaperData[index].sel = type
			},
			// 获取今日热榜
			getHotBotWallpaper(){
				hotBotWallpaper().then(res=>{
					this.hotBotList = res.data
					if(res.data.length > 0){
						this.handleBotShow(0)
					}
				})
			},
			handleBotShow(index){
				this.hotBotInfo = this.wallpaperData[index]
			},
			// 今日热榜点击切换
			changeBotIndex(index){
				if(this.bannerIndex != index){
					this.bannerIndex = index
					this.handleBotShow(index)
				}
			},
			getWallpaperClassify(){
				wallpaperClassify().then(res=>{
					this.homeTab = res.data
					if(res.data.length > 0){
						this.getWallpaperList()
					}
				})
			},
			// 获取壁纸列表
			getWallpaperList(){
				let prm = {
					name:'',
					classifyId:this.homeTab[this.homeTabIndex].id,
					type:''
				}
				wallpaperList(qs.stringify(prm)).then(res=>{
					console.log(res)
					res.rows.forEach(item=>{
						item.sel = false
					})
					this.wallpaperData = res.rows
				})
			},
			changeHomes(index){
				if(this.homeTabIndex!=index){
					this.homeTabIndex=index
					// this.getWallpaperList()
				}
			},
			
		}
	}
</script>
<style lang="scss">
	.wallpape-boom-img{
		width: 25px;
		height: 25px;
		img{
			width: 25px;
			height: 25px;
			border-radius: 50%;
			border: 2px solid #FFFFFF;
		}
	}
	.wallpape-boom{
		position: absolute;
		left: 10px;
		right: 10px;
		bottom: 8px;
		display: flex;
		align-items: center;
		.wallpape-boom-name{
			color: #FFFFFF;
			font-size: 14px;
			margin-left: 6px;
		}
		.wallpape-boom-btn{
			margin-left: auto;
			width: 86px;
			height: 28px;
			text-align: center;
			line-height: 28px;
			background: #0EAAF3;
			box-shadow: 0px 3px 6px 1px rgba(14,170,243,0.3);
			border-radius: 4px;
			color: #FFFFFF;
			font-size: 14px;
		}
	}
	.head-item{
		position: absolute;
		top: 8px;
		left: 8px;
		right: 8px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		color: #FFFFFF;
		font-size: 12px;
		img{
			width: 30px;
			height: 17px;
		}
	}
	.ma-12{
		margin-top: 12px;
	}
	.wallpape-img{
		width: 100%;
		height: 200px;
	}
	.page-box{
		padding: 0px 16px;
	}
	.home-tab-main{
		position: fixed;
		top: 72px;
		left: 180px;
		right: 0;
		z-index: 99;
		background-color: #ffffff;
		padding: 0px 16px;
	}
	.home-tab-list{
		margin-top: 16px;
		display: flex;
		align-items: center;
		background-color: #F2F4F5;
		border-radius: 6px;
		height: 50px;
		
		// padding-left: 9px;
		.home-tab-item{
			margin-left: 9px;
			position: relative;
			height: 32px;
			line-height: 32px;
			padding: 0px 16px;
			color: #333333;
			font-size: 14px;
			font-weight: bold;
		}
		.home-tab-sel{
			background-color: #0EAAF3;
			box-shadow: 0px 3px 6px 1px rgba(14,170,243,0.3);
			border-radius: 16px 16px 16px 16px;
			color: #FFFFFF;
		}
	}
	.home-banner{
		height: 412px;
		position: relative;
		// background-color: red;
		.home-banner-img{
			width: 100%;
			height: 412px;
		}
		.home-banner-boom{
			position: absolute;
			bottom: 9px;
			left: 12px;
			display: flex;
			align-items: center;
			.boom-ren-img{
				width: 82px;
				height: 30px;
			}
			.banner-boom-num{
				color: #ffffff;
				font-size: 12px;
				padding: 2px 5px;
				border-radius: 2px;
				background-color: #000000;
				margin-left: 6px;
				margin-top: 2px;
			}
			.banner-boom-sel{
				background-color: #0EAAF3;
				box-shadow: 0px 3px 6px 1px rgba(14,170,243,0.5);
			}
		}
	}
	.home-banner-info{
		position: absolute;
		top: 9px;
		left: 0;
		right: 0;
		padding: 0px 9px;
		display: flex;
		align-items: center;
		.wall-title{
			color: #FFFFFF;
			font-size: 12px;
		}
		.wall-vip-img{
			width: 30px;
			height: 17px;
			margin-left: auto;
		}
	}
	.home-banner-ret{
		height: 200px;
		// background-color: red;
		position: relative;
	}
	.el-col-items{
		height: 200px;
		background-color: red;
		width: 100%;
	}
</style>
