<template>
	<div>
		<div class="personal-nav">
			<img src="../../assets/attestation-tips-img.png" class="personal-nav-attes"/>
			<div class="personal-user-main">
				<div class="personal-user-tou">
					<img :src="userData.avatar" class="personal-user-head"/>
					<img src="../../assets/bizhi-vip-icon.png" class="personal-vip-icon" v-if="vipData.status == 1"/>
				</div>
				
				<div class="personal-user-info">
					<div class="personal-user-name">{{userData.username || userData.nickname}}</div>
					<div class="personal-user-text">编辑个性签名</div>
					<div class="personal-user-text">请填写主页地址</div>
				</div>
				<div class="author-user-other">
					<div class="author-other-item">
						<div class="other-item-text">作品数</div>
						<div class="other-item-val">1364</div>
					</div>
					<div class="author-other-item">
						<div class="other-item-text">获赞数</div>
						<div class="other-item-val">25.1万</div>
					</div>
					<div class="author-other-item">
						<div class="other-item-text">关注数</div>
						<div class="other-item-val">64</div>
					</div>
					<div class="author-other-item">
						<div class="other-item-text">粉丝数</div>
						<div class="other-item-val">1.6万</div>
					</div>
				</div>
			</div>
			<div class="personal-nav-boom">
				<div class="personal-boom-btn gestures" v-if="vipData.status != 1" @click="memberShow = true">开通会员</div>
				<div v-if="vipData.status == 1">
					<div class="vip-telel" v-if="vipData.status == 1 && vipData.permanent==1">永久会员</div>
					<div class="vip-telel" v-if="vipData.status == 1 && vipData.permanent!=1">会员到期</div>
					<div class="vip-telel" v-if="vipData.status == 1 && vipData.permanent!=1">{{handleDate(vipData.expiry)}}</div>
				</div>
				
				<div class="personal-boom-list">
					<div class="personal-boom-item">
						<img src="../../assets/phone-icon.png" class="personal-boom-img"/>
						<div class="personal-boom-info">
							<div class="bind-name">绑定手机</div>
							<div class="bind-status">去绑定></div>
						</div>
					</div>
					<div class="personal-boom-item" style="margin-left: 94px;">
						<img src="../../assets/wechat-icon.png" class="personal-boom-img"/>
						<div class="personal-boom-info">
							<div class="bind-name">绑定微信</div>
							<div class="bind-status">已绑定</div>
						</div>
					</div>
					<!-- <div class="personal-boom-item" style="margin-left: 94px;">
						<div class="food-item">
							<img src="../../assets/currency-icons.png"/>
						</div>
						<div class="personal-boom-info">
							<div class="bind-name">猫粮余额</div>
							<div class="food-item-text">
								140<span class="gestures">充值</span>
							</div>
						</div>
					</div> -->
				</div>
			</div>
		</div>
		
		<div class="personal-tab">
			<div class="personal-tab-item gestures" v-for="(value,index) in tabList" :key="index" @click="changeTabs(index)">
				<span :class="{'personal-tab-sel':index==tabListIndex}">{{value.text}}</span>
				<i class="el-icon-arrow-down"></i>
				<div class="personal-tab-line" v-if="index<3">丨</div>
			</div>
		</div>
		<div style="padding: 0px 16px;">
			<productItem></productItem>
		</div>
		
		<el-dialog
		  title=""
		  :visible.sync="memberShow"
		  width="660px"
		  @close="memberShow = false"
		  :show-close="false">
		  <member @close="dialogClose"></member>
		</el-dialog>
	</div>
</template>

<script>
	import productItem from "@/components/productItem.vue"
	import member from "@/components/member.vue"
	import { getStorage, setStorage, removeStorage } from "../../../csdPublic/utils/storage"
	export default {
		components:{productItem,member},
		data() {
			return {
				tabList:[
					{text:'我下载的'},
					{text:'我喜欢的'},
					{text:'我赞赏的'},
					{text:'我的作品'},
				],
				tabListIndex:0,
				userData:{},
				vipData:{},
				memberShow:false
			}
		},
		watch:{
			'$store.state.userInfo':{
				handler(val) {
					if(val){
						console.log(val)
						this.userData = val
					}
				}
			},
			'$store.state.vipInfo':{
				handler(val) {
					if(val){
						this.vipData = val
					}
				}
			},
		},
		created() {
			if(getStorage("token")){
				this.userData = JSON.parse(getStorage('userInfo'))
				this.vipData = JSON.parse(getStorage('vipInfo'))
				// this.getUserInfo()
			}
		},
		methods:{
			changeTabs(index){
				if(this.tabListIndex!=index){
					this.tabListIndex=index
				}
			},
			dialogClose(){
				this.memberShow = false
			},
			handleDate(data){
				return data.split(" ")[0]
			},
		}
	}
</script>

<style lang="scss">
	.personal-tab-sel{
		color: #0EAAF3;
	}
	.vip-telel{
		text-align: center;
		color: #2D3135;
		font-size: 13px;
	}
.personal-tab{
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	.personal-tab-item{
		display: flex;
		align-items: center;
		color: #8A9297;
		font-size: 12px;
		.el-icon-arrow-down{
			font-size: 18px;
			margin-left: 8px;
		}
	}
	.personal-tab-line{
		color: #727C8E;
		font-size: 12px;
		margin: 0px 12px;
	}
}
.personal-boom-item{
	display: flex;
	align-items: center;
	.food-item{
		width: 32px;
		height: 32px;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: #FFC44E;
		border-radius: 50%;
		img{
			width: 18px;
			height: 16px;
		}
	}
	.food-item-text{
		margin-top: 4px;
		color: #353535;
		font-size: 14px;
		font-weight: bold;
		span{
			color: #0EAAF3;
			font-size: 12px;
			font-weight: 400;
			margin-left: 12px;
		}
	}
	.personal-boom-img{
		width: 32px;
		height: 32px;
	}
	.personal-boom-info{
		margin-left: 8px;
		.bind-name{
			color: #8A9297;
			font-size: 12px;
		}
		.bind-status{
			margin-top: 4px;
			color: #353533;
			font-size: 14px;
		}
	}
}
.personal-nav-boom{
	margin-top: 14px;
	display: flex;
	.personal-boom-btn{
		width: 88px;
		height: 30px;
		text-align: center;
		line-height: 30px;
		background: linear-gradient(90deg, #FCEBC6 0%, #F8C48A 100%);
		border-radius: 15px;
		color: #353533;
		font-size: 13px;
	}
	.personal-boom-list{
		margin-left: 26px;
		margin-top: 10px;
		display: flex;
	}
}
.personal-nav{
	margin: 16px;
	background-color: #F7F8F8;
	border-radius: 6px;
	padding: 40px;
	position: relative;
	.personal-nav-attes{
		position: absolute;
		right: 0;
		top: 0;
		width: 254px;
		height: 28px;
	}
	.personal-user-main{
		display: flex;
		align-items: center;
		.personal-user-head{
			width: 80px;
			height: 80px;
			border-radius: 50%;
		}
		.personal-user-tou{
			width: 80px;
			height: 80px;
			position: relative;
			.personal-vip-icon{
				position: absolute;
				position: absolute;
				width: 27px;
				height: 27px;
				right: -4px;
				top: 0;
			}
		}
		.personal-user-info{
			margin-left: 30px;
			.personal-user-name{
				color: #2D3135;
				font-size: 16px;
				// margin-top: 4px;
			}
			.personal-user-text{
				margin-top: 6px;
				color: #8A9297;
				font-size: 12px;
			}
		}
	}
	.author-user-other{
		display: flex;
		align-items: center;
		margin-left: auto;
		.author-other-item{
			margin-left: 32px;
		}
		.other-item-text{
			color: #727C8E;
			font-size: 14px;
		}
		.other-item-val{
			text-align: center;
			margin-top: 4px;
			color: #2D3135;
			font-size: 14px;
			font-weight: bold;
		}
	}
}
</style>