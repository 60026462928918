<template>
	<div class="member-main">
		<div class="member-nav">
			<img src="../assets/wallpaper-logo.png" class="member-nav-logo"/>
			<div class="member-nav-tel">123动态桌面壁纸 - 会员中心</div>
			<img src="../assets/login-close-img.png" class="member-nav-close"/>
		</div>
		<div class="member-list">
			<div class="member-item gestures" v-for="(value,index) in packageData" :key="index" 
			:class="{'member-item-sel':packageIndex==index}" @click="changePay(index)">
				<div class="member-item-name">{{value.plan_name}}</div>
				<div class="member-item-price">￥<span>{{value.price}}</span></div>
				<div class="member-item-original">￥{{value.line_price}}</div>
			</div>
		</div>
		
		<div class="member-pay-main">
			<div class="member-pay-item">
				<img :src="payInfo.url"/>
			</div>
			<div class="member-pay-info">
				<div class="member-pay-price">￥<span>{{packageData[packageIndex]?.price || 0}}</span></div>
				<div class="member-pay-text">支持支付宝、微信扫码支付</div>
				<div class="member-pay-agreement">《会员服务协议》</div>
			</div>
		</div>
		
		<div class="pay-boom-item">
			<img src="../assets/pay-details-img.png"/>
		</div>
	</div>
</template>

<script>
import pay from "../../csdPublic/pay"
import { getUserInfo, getVipInfo, getPoint } from "../../csdPublic/user"
import { getStorage, setStorage, removeStorage } from "../../csdPublic/utils/storage"
export default {
  name: 'member',
  data(){
	  return{
		  packageData:[],
		  packageIndex:0,
		  payInfo:{}
	  }
  },
  created() {
  	this.getPackageList()
  },
  methods:{
	  changePay(index){
		  if(this.packageIndex!=index){
			  this.packageIndex=index
			  this.getPayCode()
		  }
	  },
	  async getPackageList(){
		  const packageList = await pay.getPackageList()
		  this.packageData = packageList
		  this.getPayCode()
	  },
	  async getPayCode(){
		  let data = this.packageData[this.packageIndex]
		  const payData = await pay.getPayCode(0, data.id)
		  this.payInfo = payData
		  this.queryOrder()
		  console.log(payData)
	  },
	  async queryOrder(){
		try {
			await pay.payPoll(this.payInfo.order_id)
			const vipInfo = await getVipInfo()
			this.$store.commit('saveVipInfo',vipInfo)
			setStorage('vipInfo',JSON.stringify(vipInfo))
			this.$emit('close',1)
		} catch (e) {
			  console.log(e.msg)
		}
		  
	  },
  }
  
}
</script>

<style lang="scss">
.pay-boom-item{
	margin-top: 18px;
	padding: 0px 8px;
	img{
		width: 100%;
		height: 171px;
	}
}
.member-pay-main{
	margin-top: 30px;
	display: flex;
	justify-content: center;
	.member-pay-info{
		margin-left: 20px;
		.member-pay-price{
			color: #FF5F61;
			font-size: 18px;
			font-weight: bold;
			margin-top: 13px;
			span{
				font-size: 28px;
			}
		}
		.member-pay-text{
			color: #333333;
			font-size: 12px;
			margin-top: 46px;
		}
		.member-pay-agreement{
			color: #87613A;
			font-size: 12px;
			margin-top: 17px;
		}
	}
	.member-pay-item{
		background: #FFFFFF;
		border-radius: 4px;
		border: 2px solid #D5D5D5;
		padding: 13px;
		img{
			width: 120px;
			height: 120px;
		}
	}
}
.member-item:not(:last-child){
	margin-right: 16px;
}
.member-item-sel{
	border: 2px solid #ECB060!important;
	background-color: #FFF2E9;
}
.member-list{
	margin-top: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	.member-item{
		width: 110px;
		height: 120px;
		border-radius: 4px 4px 4px 4px;
		border: 2px solid #E5E2E0;
		.member-item-name{
			text-align: center;
			color: #261709;
			font-size: 16px;
			margin-top: 26px;
		}
		.member-item-price{
			text-align: center;
			margin-top: 4px;
			color: #FF5F61;
			font-size: 14px;
			font-weight: bold;
			span{
				font-size: 22px;
			}
		}
		.member-item-original{
			text-align: center;
			margin-top: 5px;
			color: #9F9C99;
			font-size: 12px;
			text-decoration:line-through;
		}
	}
}
.member-main{
	background: linear-gradient(180deg, #FFFCFA 0%, #FFF6EA 100%);
	border-radius: 6px 6px 6px 6px;
	border: 3px solid #ECB060;
	.member-nav{
		display: flex;
		align-items: center;
		padding: 12px;
		.member-nav-logo{
			width: 20px;
			height: 20px;
		}
		.member-nav-tel{
			color: #333333;
			font-size: 12px;
			margin-left: 8px;
		}
		.member-nav-close{
			margin-left: auto;
			width: 22px;
			height: 22px;
		}
	}
}
</style>